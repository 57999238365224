export default defineNuxtRouteMiddleware((to) => {
  const { user, role } = useWerkgelukUser();
  const localePath = useLocalePath();

  //TODO: /mijn-account/bedrijfsgegevens should be i18n
  if (!user.value) {
    return navigateTo(
      localePath({ name: "login", query: { redirect: to.path } }),
    );
  }

  if (role.value && role.value.type !== "admin") {
    return navigateTo(localePath({ name: "my-account" }));
  }
});
